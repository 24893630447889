export const cardsPassportData = () => {
  let cards = [
    {
      id: "fbe3d439-9806-5b6f-96d3-3e0d48332492",
      show: true,
      name: "DS-11",
      headerTitle: "<span style='white-space: nowrap;'>Brand New</span>",
      formButtonText: "Complete Online",
      slug: "new",
      formImage: {
        id: "7ab7d93c-a65a-5a10-be6b-a53944a7605e",
        url: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Start now if following applies: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "You're applying on behalf of ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "someone under the age of 16.",
                          marks: [
                            {
                              type: "bold",
                            },
                          ],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "It is your first U.S. ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "italic",
                            },
                          ],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Your previous book was issued over 15 years ago.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "If you are over 16 and were aged 15 or under when you were issued your last document.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 1,
      orderByTemplate: {
        default: 1,
        template_1: 2,
      },
    },
    {
      id: "6bc785f3-7b63-506f-aa47-45191ac91a44",
      show: true,
      name: "DS-82",
      headerTitle: "Renewal",
      formButtonText: "Complete Online",
      slug: "renewal",
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: false,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Start now if your previous document:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Is in your possession and is undamaged.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Was issued when you were aged 16 or older.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Has few blank pages remaining.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Will expire soon or has expired in the last 5 years.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Was issued 1+ years ago and you have since legally changed your name.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      alertIcon: "certified-check.svg",
      alertText: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "paragraph",
              data: {},
              content: [
                {
                  nodeType: "text",
                  value: "Over ",
                  marks: [],
                  data: {},
                },
                {
                  nodeType: "text",
                  value: "140,000 ",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "text",
                  value: "disclaimer_passport_application_1_es_regular",
                  marks: [
                    {
                      type: "code",
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "text",
                  value: "s processed.",
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
      },
      order: 2,
      orderByTemplate: {
        default: 2,
        template_1: 1,
      },
    },
    {
      id: "fc8e81b7-09c9-5cb0-bcd3-da26d380677c",
      name: "DS-64",
      headerTitle: "<span style='white-space: nowrap;'>Lost/Stolen</span>",
      formButtonText: "Complete Online",
      slug: "lost-stolen",
      show: true,
      formImage: {
        id: "e8a3adb2-ccec-51d8-8274-72c3ae9e1b27",
        url: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png?w=95&h=122&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Start now if your document: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Was lost or stolen and you wish to report the loss.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Was lost or stolen and you wish to report the loss AND apply for a new ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "italic",
                            },
                          ],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 3,
      orderByTemplate: {
        default: 3,
        template_1: 3,
      },
    },
    {
      id: "e83c32e8-9e8d-4286-bdd4-c3bf72902000",
      show: true,
      name: "DS-5504",
      headerTitle: "Changes and Corrections",
      formButtonText: "Apply now",
      slug: "passport-corrections",
      newLabel: true,
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "Start now if the following applies: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Your ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "passport_svg",
                          marks: [
                            {
                              type: "code",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value:
                            " has a printing error that occured within a year of its issuing date.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Your ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "passport_svg",
                          marks: [
                            {
                              type: "code",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: " is in good condition.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "You have documentation to support the change or correction you are requesting.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "You have a “Limited Validity ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "passport_svg",
                          marks: [
                            {
                              type: "code",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "”.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 5,
      orderByTemplate: {
        default: 5,
        template_1: 5,
      },
    },
  ];

  // TODO: Sort array cards by defined attributes
  return cards.sort((item, prevItem) => {
    const template = "default";

    if (typeof item.orderByTemplate === "object") {
      return item.orderByTemplate[template] - prevItem.orderByTemplate[template];
    } else {
      return item.order - prevItem.order;
    }
  });
};

export const ourBenefitsDataSvg = {
  benefitTitle: "Our Benefits",
  benefitSubtitle: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value:
                "Receive a neatly prepared document and personalized instructions with the help of our ",
            },
            {
              nodeType: "text",
              value: "benefits_online",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: " questionnaire and ",
            },
            {
              nodeType: "text",
              value: "benefits_online",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: " ",
            },
            {
              nodeType: "text",
              value: "benefits_tools",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
          ],
          data: {},
          nodeType: "paragraph",
        },
      ],
      data: {},
      nodeType: "document",
    },
  },
  benefit1: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: "Quick ",
            },
            {
              nodeType: "text",
              value: "benefits_online_bold",
              marks: [
                {
                  type: "code",
                },
              ],
              data: {},
            },
            {
              nodeType: "text",
              value: "application_form_bold_svg",
              marks: [
                {
                  type: "code",
                },
              ],
              data: {},
            },
          ],
        },
      ],
    },
  },
  benefit2: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: "Comprehensive error checking",
            },
          ],
        },
      ],
    },
  },
  benefit3: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: "Easy step-by-step instructions",
            },
          ],
        },
      ],
    },
  },
  benefit4: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: "24/7 customer support",
            },
          ],
        },
      ],
    },
  },
};

export const ourBenefitsData = {
  benefitTitle: "Our Benefits",
  benefitSubtitle: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value:
                "Receive a neatly prepared document and personalized instructions with the help of our ",
            },
            {
              nodeType: "text",
              value: "benefits_online",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: " questionnaire and ",
            },
            {
              nodeType: "text",
              value: "benefits_online",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: " ",
            },
            {
              nodeType: "text",
              value: "benefits_tools",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
          ],
          data: {},
          nodeType: "paragraph",
        },
      ],
      data: {},
      nodeType: "document",
    },
  },
  benefit1: "Quick online application form",
  benefit2: "Comprehensive error checking",
  benefit3: "Easy step-by-step instructions",
  benefit4: "24/7 customer support",
};

export const stepsData = {
  edges: [
    {
      node: {
        id: "13de9fd1-8c3c-5f84-8410-afe95ccf68db",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "Step 1",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "Complete your ",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "benefits_form",
                    marks: [
                      {
                        type: "code",
                      },
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: " quickly and easily, avoiding costly mistakes by using our pro ",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "benefits_online",
                    marks: [
                      {
                        type: "code",
                      },
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: " ",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "benefits_tools",
                    marks: [
                      {
                        type: "code",
                      },
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 1,
      },
    },
    {
      node: {
        id: "0eaa22da-408c-5cdc-921e-f5c2f683607a",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "Step 2",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value:
                      "Follow our personalized instructions then simply print it off and submit it at your local processing office",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 2,
      },
    },
    {
      node: {
        id: "738902d5-db5a-5d8c-a61f-c7d2ab35dcc5",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: " ",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "All ",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "covid_goverment",
                    marks: [
                      {
                        type: "code",
                      },
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: " fees are paid separately ",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "hyperlink",
                    content: [
                      {
                        nodeType: "text",
                        value: "Visit Official Site",
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {
                      uri: "https://travel.state.gov/content/passports/en/passports.html",
                    },
                  },
                  {
                    nodeType: "text",
                    value: "",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 3,
      },
    },
  ],
};

export const stepsHowItWorks = [
  {
    icon: "/images/how-it-works/illustration-form.svg",
    copy: {
      raw: {
        nodeType: "document",
        data: {},
        content: [
          {
            nodeType: "heading-3",
            content: [
              {
                nodeType: "text",
                value: "Select your ",
                marks: [],
                data: {},
              },
              {
                nodeType: "text",
                value: "form_big_bold",
                marks: [
                  {
                    type: "code",
                  },
                ],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: "paragraph",
            content: [
              {
                nodeType: "text",
                value: "Choose the ",
                marks: [],
                data: {},
              },
              {
                nodeType: "text",
                value: "passport_svg",
                marks: [
                  {
                    type: "code",
                  },
                ],
                data: {},
              },
              {
                nodeType: "text",
                value: " that best meets your needs! ",
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  },
  {
    icon: "/images/how-it-works/illustration-online.svg",
    copy: {
      raw: {
        nodeType: "document",
        data: {},
        content: [
          {
            nodeType: "heading-3",
            content: [
              {
                nodeType: "text",
                value: "Fill it out  ",
                marks: [],
                data: {},
              },
              {
                nodeType: "text",
                value: "online_big_bold",
                marks: [
                  {
                    type: "code",
                  },
                ],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: "paragraph",
            content: [
              {
                nodeType: "text",
                value: "Complete your ",
                marks: [],
                data: {},
              },
              {
                nodeType: "text",
                value: "benefits_form",
                marks: [
                  {
                    type: "code",
                  },
                ],
                data: {},
              },
              {
                nodeType: "text",
                value: " using our pro ",
                marks: [],
                data: {},
              },
              {
                nodeType: "text",
                value: "benefits_online",
                marks: [
                  {
                    type: "code",
                  },
                ],
                data: {},
              },
              {
                nodeType: "text",
                value: " wizard!",
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  },
  {
    icon: "/images/how-it-works/illustration-revision.svg",
    copy: {
      raw: {
        nodeType: "document",
        data: {},
        content: [
          {
            nodeType: "heading-3",
            content: [
              {
                nodeType: "text",
                value: "Professional revision",
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: "paragraph",
            content: [
              {
                nodeType: "text",
                value:
                  "Your document will be revised by our experienced experts to ensure maximum optimization.",
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  },
  {
    icon: "/images/how-it-works/illustration-print.svg",
    copy: {
      raw: {
        nodeType: "document",
        data: {},
        content: [
          {
            nodeType: "heading-3",
            content: [
              {
                nodeType: "text",
                value: "Receive and Print",
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: "paragraph",
            content: [
              {
                nodeType: "text",
                value:
                  "Once you get your expertly reviewed document, you can simply print it and mail it in/deliver it to a ",
                marks: [],
                data: {},
              },
              {
                nodeType: "text",
                value: "passport_svg",
                marks: [
                  {
                    type: "code",
                  },
                ],
                data: {},
              },
              {
                nodeType: "text",
                value: " office depending on your needs.",
                marks: [],
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  },
];
